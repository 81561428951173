import { ApolloClient, InMemoryCache, split, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getMainDefinition } from '@apollo/client/utilities';
import createUploadLink from 'apollo-upload-client/public/createUploadLink.js';

import { OperationDefinitionNode } from 'graphql';
import { WS_URL, HTTP_URL } from './config';
import { Client, ClientOptions, createClient } from 'graphql-ws';
import { onGenericError, onStringError } from './lib/errorReporter';
import { initFirebase } from './auth';
import EventEmitter from 'eventemitter3';

const GRAPHQL_WS_URL = `${WS_URL}/graphql`;
const GRAPHQL_HTTP_URL = `${HTTP_URL}/graphql`;

async function getAuth(): Promise<string | undefined> {
  const cu = firebase.auth().currentUser;
  if (cu) {
    const token = await cu.getIdToken();
    // const token = 'playground ' + JSON.stringify({ sub: auth.currentUser.email });
    return `Bearer ${token}`;
  } else {
    return undefined;
  }
}

let connected = false;
const wsClient = createRestartableClient({
  url: GRAPHQL_WS_URL,
  connectionParams: async () => {
    return {
      authorization: await getAuth(),
    };
  },
  on: {
    connected: () => {
      console.log('connected ws');
      connected = true;
      resetStore();
    },
    closed: () => {
      connected = false;
      console.log('disconnected ws');
    }
  },
  lazy: true,
  lazyCloseTimeout: 999999999,
  retryAttempts: 9999999,
  onNonLazyError: (err) => {
    if (err instanceof CloseEvent) {
      console.log('non lazy err ' + err);
    } else if (err instanceof Error) {
      onGenericError(err);
    }
  }
});

const wsLink = new GraphQLWsLink(wsClient);

const httpLink: ApolloLink = createUploadLink({
  uri: GRAPHQL_HTTP_URL,
  fetch: async (uri: RequestInfo, options: RequestInit) => {
    // tslint:disable-next-line: no-string-literal
    options.headers!['Authorization'] = await getAuth();
    return fetch(uri, options);
  }
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation, name } = getMainDefinition(query) as OperationDefinitionNode;
    return (kind === 'OperationDefinition' && operation === 'mutation' && (name?.value.includes('Upload') ?? false));
  },
  httpLink,
  wsLink,
);

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        onStringError(graphQLErrors.map((x) => x.message).join('; '));
        graphQLErrors.map(({ message, locations, path }) =>
          // tslint:disable-next-line:no-console
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
          ),
        );
      }
      if (networkError) {
        onStringError('Error de red: ' + networkError);
        // tslint:disable-next-line:no-console
        console.log(`[Network error]: ${networkError}`);
      }
    }),
    link
  ]),
  cache: new InMemoryCache()
});

interface RestartableClient extends Client {
  restart(): void;
}

function createRestartableClient(options: ClientOptions): RestartableClient {
  let restartRequested = false;
  let restart = () => {
    restartRequested = true;
  };

  const newClient = createClient({
    ...options,
    on: {
      ...options.on,
      opened: (socket) => {
        const ws = socket as WebSocket;
        restart = () => {
          if (ws.readyState === WebSocket.OPEN) {
            // if the socket is still open for the restart, do the restart
            ws.close(4205, 'Client Restart');
          } else {
            // otherwise the socket might've closed, indicate that you want
            // a restart on the next opened event
            restartRequested = true;
          }
        };

        // just in case you were eager to restart
        if (restartRequested) {
          restartRequested = false;
          restart();
        }
      },
    },
  });

  return {
    ...newClient,
    restart: () => restart(),
  };
}

let currentUser: firebase.User | null = null;

initFirebase();
firebase.auth().onAuthStateChanged(
  async (user: firebase.User | null) => {
    if (currentUser?.uid !== user?.uid && connected) {
      console.log('restartClientAuth');
      currentUser = user;
      wsClient.restart();
    }
  }
);

const refetchEmitter = new EventEmitter();
export const subscribeRefetch = (f: VoidFunction) => {
  refetchEmitter.addListener('reset', f);
};

export const unsubscribeRefetch = (f: VoidFunction) => {
  refetchEmitter.removeListener('reset', f);
};

export const resetStore = async () => {
  // https://github.com/apollographql/@apollo/client/issues/3555
  await client.cache.reset();
  refetchEmitter.emit('reset');
  await client.reFetchObservableQueries();

};

export const refetch = async () => {
  refetchEmitter.emit('reset');
  await client.reFetchObservableQueries();
};