import { InvoiceCategory, InvoiceRectification, InvoiceType } from '../../../gen/globalTypes';
import { isValidAmount, parseAmount } from '../../../gen/common/parsers/parseAmount';
import { isValidDate, parseDate } from '../../../gen/common/parsers/parseDate';
import { isValidCurrency, parseCurrency } from '../../../gen/common/parsers/parseCurrency';
import { isValidCountry, parseCountry } from '../../../gen/common/parsers/parseCountry';
import { isValidZipcode as isValidZipcodeForCountry, parseZipcode } from '../../../gen/common/parsers/parseZipcode';
import { isValidName, parseName } from '../../../gen/common/parsers/parseName';
import { isValidPercent, parsePercent } from '../../../gen/common/parsers/parsePercent';
import { isValidTaxIdForCountry, parseTaxId } from '../../../gen/common/parsers/parseTaxId';
import { isValidCode, parseCode } from '../../../gen/common/parsers/parseCode';

export type ExtractionContext = { [s: string]: string };

export interface Field {
  id: string;
  name: string;
  isValid: (s: string, context: ExtractionContext) => boolean;
  parse: (raw: string, context: ExtractionContext) => string;
  choices?: { v: string, h: string }[];
  header?: string;
  isArray?: true;
  children?: Field[]
}

export const isValidTaxId = (countryField: string) => (s: string, context: ExtractionContext): boolean => {
  const country = context[countryField];
  return isValidTaxIdForCountry(country, s);
};

export const isValidZipcode = (countryField: string) => (s: string, context: ExtractionContext): boolean => {
  const country = context[countryField];
  return isValidZipcodeForCountry(country, s);
};

interface Choice {
  v: string, // value
  h: string //human string
}
const CATEGORY_CHOICES: Choice[] = [
  { v: InvoiceCategory.PRODUCT, h: "Producto" },
  { v: InvoiceCategory.SERVICE, h: "Servicio" },
  { v: InvoiceCategory.GASOLINE, h: "Gasolina" }
];

const TAX_CHOICES = [
  { v: "IVA", h: "IVA" },
  { v: "IRPF", h: "IRPF" },
  { v: "IVARE", h: "Recargo Equivalencia" },
  { v: "IGIC", h: "IGIC/IPSI" },
];

const DIRECTIONS = [{ v: "send", h: "Enviada" }, { v: "receive", h: "Recibida" }];

const RECTIFICATION_CHOICES = [{ v: InvoiceRectification.NO, h: "No" }, { v: InvoiceRectification.REPLACEMENT, h: "Reemplazo" }];

const INVOICEV2_TIPO = [
  { v: "F1", h: "Factura" },
  { v: "F2", h: "Simplificada" },
  { v: "F3", h: "Tickets agrupados" },
  { v: "R1", h: "Rectificativa" },
  { v: "R5", h: "Simplificada Rectificativa" },
  { v: "SF", h: "Sin factura" }];


const INVOICETYPE_CHOICES = [
  { v: InvoiceType.NORMAL, h: "Normal" },
  { v: InvoiceType.ISP, h: "ISP" },
  // InvoiceType.CAJA,
  // InvoiceType.TRANSITARIO,
];

const isValidChoice = (choices: Choice[]) => (v: string) => choices.some((c) => c.v === v);
const parseChoice = (choices: Choice[]) => () => choices[0].v;

export const invoiceFields: Field[] = [
  { id: 'number', name: 'Núm. Factura', isValid: isValidCode, parse: parseCode },
  { id: 'date', name: 'Fecha emisión', isValid: isValidDate, parse: parseDate },

  { id: 'senderId', name: 'NIF emisor', isValid: isValidTaxId('senderCountry'), parse: parseTaxId, header: 'Emisor' },
  { id: 'senderName', name: 'Nombre emisor', isValid: isValidName, parse: parseName },
  { id: 'senderCountry', name: 'País emisor', isValid: isValidCountry, parse: parseCountry },
  { id: 'senderZipcode', name: 'Código postal emisor', isValid: isValidZipcode('senderCountry'), parse: parseZipcode },

  {
    id: 'receiverId', name: 'NIF receptor', isValid: isValidTaxId('receiverCountry'), parse: parseTaxId,
    header: 'Receptor'
  },
  { id: 'receiverName', name: 'Nombre receptor', isValid: isValidName, parse: parseName },
  { id: 'receiverCountry', name: 'País receptor', isValid: isValidCountry, parse: parseCountry },
  {
    id: 'receiverZipcode', name: 'Código postal receptor', isValid: isValidZipcode('receiverCountry'),
    parse: parseZipcode
  },

  { id: 'currency', name: 'Moneda', isValid: isValidCurrency, parse: parseCurrency, header: 'Totales' },
  { id: 'grossTotal', name: 'Total sin impuestos', isValid: isValidAmount, parse: parseAmount },
  { id: 'netTotal', name: 'Total con impuestos', isValid: isValidAmount, parse: parseAmount },

  { id: 'iva21Amount', name: 'Cuota IVA 21%', isValid: isValidAmount, parse: parseAmount },
  { id: 'iva10Amount', name: 'Cuota IVA 10%', isValid: isValidAmount, parse: parseAmount },
  { id: 'iva10Base', name: 'Base IVA 10% (EUR)', isValid: isValidAmount, parse: parseAmount },
  { id: 'iva5Amount', name: 'Cuota IVA 5%', isValid: isValidAmount, parse: parseAmount },
  { id: 'iva5Base', name: 'Base IVA 5% (EUR)', isValid: isValidAmount, parse: parseAmount },
  { id: 'iva4Amount', name: 'Cuota IVA 4%', isValid: isValidAmount, parse: parseAmount },
  { id: 'iva4Base', name: 'Base IVA 4% (EUR)', isValid: isValidAmount, parse: parseAmount },
  { id: 'iva0Base', name: 'Base IVA 0% (EUR)', isValid: isValidAmount, parse: parseAmount },

  { id: 'ivaREAmount', name: 'Cuota IVA RE (EUR)', isValid: isValidAmount, parse: parseAmount },

  // { id: 'ivaForeign', name: 'Cuota IVA Extranjero' },

  { id: 'irpfAmount', name: 'Retención IRPF', isValid: isValidAmount, parse: parseAmount },
  { id: 'irpfPercent', name: 'Retención IRPF porcentaje', isValid: isValidPercent, parse: parsePercent },

  {
    id: 'category', name: 'Categoria', choices: CATEGORY_CHOICES,
    isValid: isValidChoice(CATEGORY_CHOICES), parse: parseChoice(CATEGORY_CHOICES)
  },
  {
    id: 'rectification', name: 'Rectificativa', choices: RECTIFICATION_CHOICES,
    isValid: isValidChoice(RECTIFICATION_CHOICES), parse: parseChoice(RECTIFICATION_CHOICES)
  },
  {
    id: 'invoiceType', name: 'Tipo', choices: INVOICETYPE_CHOICES,
    isValid: isValidChoice(INVOICETYPE_CHOICES), parse: parseChoice(INVOICETYPE_CHOICES)
  },

];

export const invoiceV2Fields: Field[] = [
  { id: 'direction', name: 'Sentido', isValid: isValidChoice(DIRECTIONS), parse: parseChoice(DIRECTIONS), choices: DIRECTIONS },
  {
    id: 'type', name: 'Tipo', choices: INVOICEV2_TIPO,
    isValid: isValidChoice(INVOICEV2_TIPO), parse: parseChoice(INVOICEV2_TIPO)
  },
  { id: 'number', name: 'Núm. Factura', isValid: isValidCode, parse: parseCode },
  { id: 'date', name: 'Fecha emisión', isValid: isValidDate, parse: parseDate },

  { id: 'contact', name: 'NIF', isValid: isValidTaxId('country'), parse: parseTaxId },
  { id: 'name', name: 'Nombre', isValid: isValidName, parse: parseName },
  { id: 'country', name: 'País', isValid: isValidCountry, parse: parseCountry },
  { id: 'zipcode', name: 'Código postal', isValid: isValidZipcode('country'), parse: parseZipcode },

  { id: 'currency', name: 'Moneda', isValid: isValidCurrency, parse: parseCurrency, header: 'Totales' },
  { id: 'total', name: 'Total factura', isValid: isValidAmount, parse: parseAmount },
  { id: 'disbursement', name: 'Suplido', isValid: isValidAmount, parse: parseAmount },
  {
    id: 'taxes', name: 'Impuestos', isValid: () => true, parse: () => "", header: "Impuestos", isArray: true, children: [
      { id: "tax", name: "Impuesto", choices: TAX_CHOICES, isValid: isValidChoice(TAX_CHOICES), parse: parseChoice(TAX_CHOICES) },
      { id: "rate", name: "Porcentaje", isValid: isValidPercent, parse: parsePercent },
      { id: "amount", name: "Cantidad", isValid: isValidAmount, parse: parseAmount },
      { id: "base", name: "Base", isValid: isValidAmount, parse: parseAmount },
    ]
  },
  {
    header: "Info",
    id: 'category', name: 'Categoria', choices: CATEGORY_CHOICES,
    isValid: isValidChoice(CATEGORY_CHOICES), parse: parseChoice(CATEGORY_CHOICES)
  },
];


export const bankStatementFields: Field[] = [
  { id: 'number', name: 'Id extracto', isValid: isValidCode, parse: parseCode },
  { id: 'date', name: 'Fecha emisión', isValid: isValidDate, parse: parseDate },

  { id: 'senderId', name: 'NIF emisor', isValid: isValidTaxId('senderCountry'), parse: parseTaxId, header: 'Emisor' },
  { id: 'senderName', name: 'Nombre emisor', isValid: isValidName, parse: parseName },
  { id: 'senderCountry', name: 'País emisor', isValid: isValidCountry, parse: parseCountry },
  { id: 'senderZipcode', name: 'Código postal emisor', isValid: isValidZipcode('senderCountry'), parse: parseZipcode },

  { id: 'netTotal', name: 'Total con impuestos', isValid: isValidAmount, parse: parseAmount },
  { id: 'currency', name: 'Moneda', isValid: isValidCurrency, parse: parseCurrency },

];

export const autonomosReceiptFields: Field[] = [
  { id: 'date', name: 'Fecha emisión', isValid: isValidDate, parse: parseDate },
  { id: 'netTotal', name: 'Total', isValid: isValidAmount, parse: parseAmount },
];

// export const salesSummaryFields: Field[] = [
//   { id: 'number', name: 'Núm. Ticket inicial', isValid: isValidCode, parse: parseCode },
//   { id: 'numberEnd', name: 'Núm. Ticket final', isValid: isValidCode, parse: parseCode },
//   { id: 'date', name: 'Fecha emisión', isValid: isValidDate, parse: parseDate },

//   { id: 'grossTotal', name: 'Total sin impuestos', isValid: isValidAmount, parse: parseAmount, header: 'Totales' },
//   { id: 'netTotal', name: 'Total con impuestos', isValid: isValidAmount, parse: parseAmount },
//   { id: 'currency', name: 'Moneda', isValid: isValidCurrency, parse: parseCurrency },

//   { id: 'iva21Amount', name: 'Cuota IVA 21%', isValid: isValidAmount, parse: parseAmount },
//   { id: 'iva10Amount', name: 'Cuota IVA 10%', isValid: isValidAmount, parse: parseAmount },
//   { id: 'iva4Amount', name: 'Cuota IVA 4%', isValid: isValidAmount, parse: parseAmount },
// ];

// export const taxNotificationFields: Field[] = [
//   { id: 'number', name: 'Código CSV', isValid: isValidCode, parse: parseCode },
//   { id: 'date', name: 'Fecha emisión', isValid: isValidDate, parse: parseDate },
// ];