import * as React from 'react';
import { Link } from 'react-router-dom';
import { TaskOrigin } from '../gen/globalTypes';

const uuidRegex = /[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/ig;

function* matches(text: string, pattern: RegExp) {
  pattern.lastIndex = 0;
  let match = null;
  do {
    match = pattern.exec(text);
    if (match) {
      yield match;
    }
  } while (match);
}

interface TextItem {
  docId: string | null;
  title: { es: string };
  origin: TaskOrigin;
  sleepDate: string | null;
  delayUntil: string | null;
  taskGroup?: {
    id: string;
    title: { es: string };
  } | null;
  sleeping: boolean;
  taskStatus: "DELAYED" | "PENDING" | "COMPLETED" | "LOCKED" | "HIDDEN";
}

export const TaskText = (props: { data: TextItem, company: string }) => {
  let indexNotMatched = 0;
  const res = [];
  const style: React.CSSProperties = { fontWeight: props.data.origin === TaskOrigin.MANUAL ? 500 : 'inherit' };

  switch (props.data.taskStatus) {
    case "PENDING":
      break;
    case "COMPLETED":
      res.push(<span key="status">✅ </span>);
      style['color'] = 'rgba(0,0,0,0.3)';
      style.textDecoration = 'line-through';
      break;
    case "LOCKED":
      res.push(<span key="status">🔑 </span>);
      style['color'] = 'rgba(0,0,0,0.3)';
      style.textDecoration = 'line-through';
      break;
    case "DELAYED":
      res.push(<span key="status">💤 {props.data.delayUntil} </span>);
      style['color'] = 'rgba(0,0,0,0.3)';
      style.textDecoration = 'line-through';
      break;
    case "HIDDEN":
      res.push(<span key="status">👻 </span>);
      style['color'] = 'rgba(0,0,0,0.3)';
      style.textDecoration = 'line-through';
      break;
  }
  if (props.data.sleeping) {
    res.push(<span key="sleep">💤 {props.data.sleepDate} </span>);
    style['color'] = 'rgba(0,0,0,0.3)';
    style.textDecoration = 'line-through';
  }

  if (props.data.taskGroup?.title.es && props.data.taskGroup?.id !== 'errors') {
    res.push(<span style={{ 'paddingRight': '5px' }}>({props.data.taskGroup.title.es})</span>);
  }

  for (const match of matches(props.data.title.es, uuidRegex)) {
    if (match.index !== indexNotMatched) {
      res.push(<span key={match.index}>{props.data.title.es.slice(indexNotMatched, match.index)}</span>);
    }
    res.push(
      <Link
        onClick={(e) => e.stopPropagation()}
        to={`/company/${props.company}/documents/${match[0]}`}
        key={match.index}
      >
        {match[0]}
      </Link >
    );
    indexNotMatched = match.index + match[0].length;
  }
  if (indexNotMatched !== props.data.title.es.length) {
    res.push(<span key="e">{props.data.title.es.slice(indexNotMatched)}</span>);
  }
  return <span style={style}>
    {res}
  </span>
};