import { ExtractionContentType, ExtractionTaskType } from "../../gen/globalTypes";

enum Solutions {
  Reupload = "Reupload",
  Delete = "Delete"
}

export interface AutocompleteTask {
  userMessage: { es: string, ca: string };
  helpLink?: string;
  userTaskType: ExtractionTaskType;
  contentType: ExtractionContentType;
  solutions: Solutions[]
}

export const autocompleteTasks: AutocompleteTask[] = [
  {
    userMessage: {
      es: "",
      ca: ""
    },
    solutions: [],
    helpLink: "",
    userTaskType: ExtractionTaskType.MESSAGE,
    contentType: ExtractionContentType.OTHER_DOC,
  },
  {
    userMessage: {
      es: "Factura simplificada, faltan tus datos fiscales (DNI + Nombre completo + Domicilio) para poder deducir el IVA.",
      ca: "",
    },
    solutions: [],
    helpLink: "5634560",
    userTaskType: ExtractionTaskType.MESSAGE,
    contentType: ExtractionContentType.INVOICE,

  },
  {
    userMessage: {
      es: "Factura intracomunitaria a cliente final. No se puede deducir el IVA.",
      ca: "",
    },
    solutions: [],
    helpLink: "5634562",
    userTaskType: ExtractionTaskType.MESSAGE,
    contentType: ExtractionContentType.INVOICE,
  },
  {
    userMessage: {
      es: "La factura no va a tu nombre. No se ha contabilizado.",
      ca: "",
    },
    solutions: [],
    helpLink: "5610250",
    userTaskType: ExtractionTaskType.IGNORED,
    contentType: ExtractionContentType.OTHER_DOC,
  },
  {
    userMessage: {
      es: "Un albarán, presupuesto o pedido no se puede contabilizar. Sube la factura de la compra para contabilizarla.",
      ca: "",
    },
    solutions: [
      Solutions.Delete
    ],
    helpLink: "5634565",
    userTaskType: ExtractionTaskType.IGNORED,
    contentType: ExtractionContentType.OTHER_DOC,

  },
  {
    userMessage: {
      es: "Es un recibo bancario o comprobante de pago. Necesitamos la factura correspondiente para contabilizarla.",
      ca: "",
    },
    solutions: [
      Solutions.Delete
    ],
    helpLink: "5634566",
    userTaskType: ExtractionTaskType.IGNORED,
    contentType: ExtractionContentType.OTHER_DOC,

  },
  {
    userMessage: {
      es: "No se pueden leer los datos del proveedor, se encuentran ocultos por el justificante de pago.",
      ca: "",
    },
    solutions: [
      Solutions.Delete,
      Solutions.Reupload
    ],
    helpLink: "5634567",
    userTaskType: ExtractionTaskType.UNREADABLE,
    contentType: ExtractionContentType.OTHER_DOC,

  },
  {
    userMessage: {
      es: "No se pueden leer todos los detalles necesarios.",
      ca: "",
    },
    solutions: [
      Solutions.Delete,
      Solutions.Reupload
    ],
    helpLink: "5634567",
    userTaskType: ExtractionTaskType.UNREADABLE,
    contentType: ExtractionContentType.OTHER_DOC,

  },
  {
    userMessage: {
      es: "Documento censal / Seguridad Social / Mutua.",
      ca: "",
    },
    solutions: [],
    helpLink: "",
    userTaskType: ExtractionTaskType.MESSAGE,
    contentType: ExtractionContentType.OTHER_DOC,

  },
  {
    userMessage: {
      es: "¿Es un gasto personal? Recuerda que solo se pueden deducir gastos relacionados con tu actividad económica.",
      ca: "",
    },
    solutions: [],
    helpLink: "5634568",
    userTaskType: ExtractionTaskType.MESSAGE,
    contentType: ExtractionContentType.INVOICE,
  },
  {
    userMessage: {
      es: "¿Es un gasto personal? Recuerda que solo se pueden deducir gastos relacionados con tu actividad económica.",
      ca: "",
    },
    solutions: [],
    helpLink: "5634568",
    userTaskType: ExtractionTaskType.MESSAGE,
    contentType: ExtractionContentType.INVOICE_V2,
  },
  {
    userMessage: {
      es: "Factura simplificada, faltan tus datos fiscales (DNI + Nombre completo + Domicilio) para poder deducir el IVA.",
      ca: "",
    },
    solutions: [],
    helpLink: "5634560",
    userTaskType: ExtractionTaskType.MESSAGE,
    contentType: ExtractionContentType.INVOICE_V2,

  },
  {
    userMessage: {
      es: "Factura intracomunitaria a cliente final. No se puede deducir el IVA.",
      ca: "",
    },
    solutions: [],
    helpLink: "5634562",
    userTaskType: ExtractionTaskType.MESSAGE,
    contentType: ExtractionContentType.INVOICE_V2,
  },
];
