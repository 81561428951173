import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './app';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { client } from './client';
import { ApolloProvider } from '@apollo/client';
import { Router } from 'react-router-dom';
import { SignInScreen } from './auth';
import { initErrorReporter } from './lib/errorReporter';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import { theme } from './theme';
import gql from 'graphql-tag';
import moment from 'moment';
import { history } from './lib/utilBrowser';
import ErrorReporter from './lib/errorReporter';
import { HTTP_URL } from './config';

import 'moment/locale/es';
moment.locale('es');

initErrorReporter();

interface AppState {
  userId: string | null;
}

// tslint:disable-next-line:no-string-literal
window['graphql'] = client;
// tslint:disable-next-line:no-string-literal
window['gql'] = gql;
(window as any)['ABAQ_API'] = HTTP_URL;

class AppScreen extends React.Component<{}, AppState> {
  state = {
    userId: null,
  } as AppState;

  render = () => {
    return (
      <SignInScreen
        onUserLogged={(userId: string) => {
          this.setState({ userId });
        }}
      >
        <ApolloProvider client={client}>
          <MuiPickersUtilsProvider utils={DayjsUtils}>
            <Router history={history}>
              <App userId={this.state.userId} />
              <ErrorReporter />
            </Router>
          </MuiPickersUtilsProvider>
        </ApolloProvider>
      </SignInScreen>
    );
  }
}

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <AppScreen />
  </MuiThemeProvider>
  ,
  document.querySelector('#root')
);
