import * as React from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import IconButton from '@material-ui/core/IconButton';
import MUILink from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import { RouteComponentProps, Link, withRouter } from 'react-router-dom';
import { QCompanyTitleVariables, QCompanyTitle } from '../gen/QCompanyTitle';
import gql from 'graphql-tag';
import DesktopMac from '@material-ui/icons/DesktopMac';
import MasterDocumentIcon from '@material-ui/icons/SchoolSharp';
import ActionsDocumentIcon from '@material-ui/icons/TimelapseSharp';

const QUERY = gql`
  query QCompanyTitle($company: ID!) {
    company(id: $company) {
      id
      name,
      taxId,
      masterDoc: docs(type: STANDALONE) {
        items {
          id,
        }
      }
      actionsDoc: docs(type: ACTIONS) {
        items {
          id,
        }
      }
    }
  }
`;

export const CompanyTitleById = (props: { id: string }) => {
  const { data } = useQuery<QCompanyTitle, QCompanyTitleVariables>(
    QUERY,
    {
      variables: { company: props.id },
      fetchPolicy: 'no-cache'
    }
  );
  if (!data || !data.company) {
    return <span>Abaq</span>;
  }
  document.title = data.company.name || 'Abaq - ?';
  const name = `${data.company.name || 'Empresa'} - ${data.company.taxId}`;
  return (
    <span>
      <MUILink
        component={Link}
        color="inherit"
        to={`/company/${props.id}`}
      >
        {name}
      </MUILink>
      <span>&nbsp;&nbsp;</span>
      {
        data.company.masterDoc.items.length > 0 ? (
          <Tooltip title="Documento maestro">
            <IconButton
              color="inherit"
              component={Link}
              to={`/company/${props.id}/documents/${data.company.masterDoc.items[0].id}`}
            >
              <MasterDocumentIcon color="inherit" />
            </IconButton>
          </Tooltip>
        ) : null
      }
      {
        data.company.actionsDoc.items.length > 0 ? (
          <Tooltip title="Registro de acciones">
            <IconButton
              color="inherit"
              component={Link}
              to={`/company/${props.id}/documents/${data.company.actionsDoc.items[0].id}`}
            >
              <ActionsDocumentIcon color="inherit" />
            </IconButton>
          </Tooltip>
        ) : null
      }
      <Tooltip title="App Web">
        <IconButton
          disabled={data.company == null || data.company.taxId == null}
          color="inherit"
          onClick={() => {
            if (data.company != null && data.company.taxId != null) {
              const w = window.open(`https://www.abaq.app/a/company/${data.company.id}/dashboard`, '_blank');
              if (w) {
                w.focus();
              }
            }
          }}
        >
          <DesktopMac />
        </IconButton>
      </Tooltip>
    </span>
  );
};

const CompanyTitle = (props: RouteComponentProps<{ id: string }>) => {
  return (<CompanyTitleById id={props.match.params.id} />);
};

export default withRouter(CompanyTitle);
